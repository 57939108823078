import React, { useEffect, useState } from "react";
import Banner from "../components/banner";
import Steps from "../components/steps";
import IntroOverlay from "../components/introOverlay";
import { homeAnimation } from "../animations/homeAnimation";

const Home = ({ dimensions }) => {
    const [animationComplete, setAnimationComplete] = useState(false);
  
    const completeAnimation = () => {
       setAnimationComplete(true);
       console.log("ANIMATION COMPLETE", animationComplete)
    };
  
    useEffect(() => {
      console.log("HOME ANIMATION", animationComplete)
      homeAnimation(completeAnimation);
    }, []);
  
    useEffect(() => {
      let vh = dimensions.height * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }, [dimensions.width]);
  
    return (
      <>
        {animationComplete === false ? <IntroOverlay /> : ""}
        {/* <IntroOverlay/> */}
        <Banner />
        {/* <span>{animationComplete ? "COMPLETE": "NOT COMPLETE"}</span> */}
        <Steps />
      </>
    );
  };
  
  export default Home;