import React from 'react'
import {ReactComponent as RightArrow} from '../assets/arrow-right.svg'

function Banner() {
    return (
        <section className="main">
            <div className="container">
                <div className="row">
                    <h2>
                        <div className="line">
                            <span>Olfattometria</span>
                        </div>
                        <div className="line">
                            <span>Industriale</span>
                        </div>
                    </h2>
                    <h3>
                        <span>
                            Innovazione, metodi e soluzioni per l’industria.
                        </span>
                    </h3>
                    {/* <div className="btn-row">
                        <a href="/">
                        Innovazione, metodi e soluzioni per l’industria. <RightArrow/>
                        </a>
                    </div> */}
                </div>
            </div>
            
        </section>
    )
}

export default Banner
