import React, { useEffect, useState } from "react";
import IntroOverlay from "../components/introOverlay";
import { homeAnimation } from "../animations/homeAnimation";

const Services = () => {

  const [animationComplete, setAnimationComplete] = useState(false);
  
  const completeAnimation = () => {
     setAnimationComplete(true);
     console.log("ANIMATION COMPLETE", animationComplete)
  };

  useEffect(() => {
    console.log("HOME ANIMATION", animationComplete)
    homeAnimation(completeAnimation);
    setTimeout(()=>{
      completeAnimation()
      console.log("ANIMATION COMPLETED")
    }, 3000)
  }, []);

  return (
    <div>
      {animationComplete === false ? <IntroOverlay /> : ""}
      <div className='page_1'>
        <div className='container'>
          <div className='row'>
              <h1>Servizi</h1>
          </div>
          {/* <div className="row"> */}
            <section className="services">
            <div className="service">
                <h4>Monitoraggio</h4>
                <ul>COT con FID POLARIS POLLUTION da campo</ul>
                <ul>Fumi di combustione con rilevatore MRU OPTIMA7 da campo</ul>
                <ul>Rilevamenti parametri fluidodinamici con rilevatore MRU OPTIMA7 e TESTO440</ul>
                <ul>Monitoraggio VOC con rilevatore PID TIGER</ul>
                <ul>Impiego fumogeni fraccianti per l'individuazione di emissioni fuggitive e diffuse</ul>
            </div>
            <div className="service">
                <h4>Analisi</h4>
                <ul>Analisi olfattometriche secondo norma UNI EN 13725:2004</ul>
                <ul>Speciazione chimica in GCMS (EPA TO 15 modificato)</ul>
              </div>
            <div className="service">
                <h4>Strumentazione</h4>
                <ul>Noleggio nasi elettronici (MSEM32 Sensigent)</ul>
                <ul>Noleggio strumentazione di campionamento (pompa a vuoto, cappa statica, rilevatore di fumi di combustione, anemometro, pitot, FID)</ul>
                <ul>Noleggio sistema di campionamento olfattometrico da remoto (ODORPREP)</ul>
              </div>

              <div className="service">
                <h4>Campionamento</h4>
                <ul>Campionamento olfattometrico secondo norma UNI EN 13725:2004</ul>
              </div>
              <div className="service">
                <h4>Impianto pilota - noleggio</h4>
                <ul>Impianto pilota scrubber a umido/bioscrubber da 1500 m3/h</ul>
                <ul>Impianto pilota demister orizzontale da 1500 m3/h</ul>
                <ul>Impianto pilota adsorbitore statico a letto fisso zeolite/carbone attivo/allumina da 500m3/h</ul>
                <ul>Impianto pilota tre camere da 2500 m3/h</ul>
                <ul>Impianto pilota plasma a freddo da 5000 m3/h</ul>
                <ul>Impianto pilota biofiltro modulare da 2000 m3/h</ul>
                <ul>Fornetto elettrico per cottura supporti ceramici</ul>
              </div>
              <div className="service">
                <h4>Progettazione e calcolo</h4>
                  <ul>Progettazione, calcolo e redazione elaborati per AIA, AUA</ul>
                  <ul>Studi di dispersione dell'odore e degli inquinanti</ul>
                  <ul>Definizione di scenari migliorativi</ul>
              </div>
            </section>
          </div>

          {/* <div className="row">
            <section className="services">
              <div className="service">
                <h4>Campionamento</h4>
                <ul>Campionamento olfattometrico secondo norma UNI EN 13725:2004</ul>
              </div>
              <div className="service">
                <h4>Impianto pilota - noleggio</h4>
                <ul>Impianto pilota scrubber a umido/bioscrubber da 1500 m3/h</ul>
                <ul>Impianto pilota demister orizzontale da 1500 m3/h</ul>
                <ul>Impianto pilota adsorbitore statico a letto fisso zeolite/carbone attivo/allumina da 500m3/h</ul>
                <ul>Impianto pilota tre camere da 2500 m3/h</ul>
                <ul>Impianto pilota plasma a freddo da 5000 m3/h</ul>
                <ul>Impianto pilota biofiltro modulare da 2000 m3/h</ul>
                <ul>Fornetto elettrico per cottura supporti ceramici</ul>
              </div>
              <div className="service">
                <h4>Progettazione e calcolo</h4>
                  <ul>Progettazione, calcolo e redazione elaborati per AIA, AUA</ul>
                  <ul>Studi di dispersione dell'odore e degli inquinanti</ul>
                  <ul>Definizione di scenari migliorativi</ul>
              </div>
            </section>
          </div> */}
        </div>
      </div>
    // </div>
  );
};

export default Services;