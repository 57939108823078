import React from 'react'
import { NavLink } from "react-router-dom";


const Navigation = () => {
    return (
      <nav>
        <div className='container'>
          <div className='nav-columns'>
            <div className='nav-column'>
              <div className='nav-label'>Menu</div>
              <ul className='nav-links'>
                <li>
                  {/* <NavLink to='/services' exact> */}
                  <NavLink to='/#' exact>
                    Servizi
                  </NavLink>
                </li>
                <li>
                  {/* <NavLink to='/news' exact> */}
                  <NavLink to='/#' exact>
                    News
                  </NavLink>
                </li>
                <li>
                  {/* <NavLink to='/about' exact> */}
                  <NavLink to='/#' exact>
                    Chi siamo
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className='nav-column'>
              <div className='nav-label'>Contatti</div>
              <div className='nav-infos'>
                <ul className='nav-info'>
                  <li className='nav-info-label'>Aria 51 Srl</li>
                  <li>Sede legale Via della Cooperativa 11/H</li>
                  <li>33028 Tolmezzo (UD)</li>
                </ul>
                <ul className='nav-info'>
                  <li className='nav-info-label'>Ambiente Ricerca COnsulenze e Soluzioni Sostenibili Srl</li>
                  <li>Sede legale Via Licio Giorgeri, 1</li>
                  <li>34127 Trieste (TS)</li>
                </ul>
                <ul className='nav-info'>
                  <li className='nav-info-label'>Email</li>
                  <li>
                     <a href = "mailto: info@olfattometriaindustriale.it">info@olfattometriaindustriale.it</a>
                  </li>
                </ul>
                {/* <ul className='nav-info'>
                  <li className='nav-info-label'>Legal</li>
                  <li>Privacy & Cookies</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  };
  

export default Navigation
