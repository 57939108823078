import React, { Component } from 'react'
import background from "../assets/bg-pattern.png";
import {ReactComponent as Background} from '../assets/background_particles.svg';
import {ReactComponent as Stack1} from '../assets/Stack1.svg';
import {ReactComponent as Stack2} from '../assets/Stack2.svg';
import {ReactComponent as Stack3} from '../assets/Stack3.svg';
import {ReactComponent as Stack4} from '../assets/Stack4.svg';

import brochure from '../assets/brochure_2021_OI.pdf';

export class Steps extends Component {
    render() {
        return (
          <section className="steps">
            <div className="construction">
              <div>Sito in costruzione</div>
              <div>
                <a href="mailto: info@olfattometriaindustriale.it">
                  info@olfattometriaindustriale.it
                </a>
              </div>
              <div>
                <a className="brochure" href={brochure} rel="noopener noreferrer"  target="_blank">
                  Brochure link
                </a>
              </div>
            </div>
            {/* <div className="pattern" style={{ backgroundImage: `url(${background})` }}></div> */}
            <div className="container">
              {/* <div className="row bottom-align space-between "> */}
              <div className="stacks">
                <div className="stack">
                  <Stack1 />
                </div>
                <div className="stack">
                  <Stack2 />
                </div>
                <div className="stack">
                  <Stack4 />
                </div>
                <div className="stack">
                  <Stack3 />
                </div>
                {/* </div> */}

                {/* <div className="step">
                            <Stack1 className="stack"/>
                            <div className="step-details">
                                <h3>Studiamo</h3>
                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tempor orci dapibus ultrices in iaculis. Vitae et leo duis ut diam quam. </span>
                            </div>
                        </div>
                        <div className="step">
                            <div className="step-details">
                                <h3>Misuriamo</h3>
                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tempor orci dapibus ultrices in iaculis. Vitae et leo duis ut diam quam. </span>
                            </div>
                        </div>
                        <div className="step">
                            <div className="step-details">
                                <h3>Progettiamo</h3>
                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tempor orci dapibus ultrices in iaculis. Vitae et leo duis ut diam quam. </span>
                            </div>
                        </div> */}
              </div>
            </div>
            <Background className="background" />
          </section>
        );
    }
}

export default Steps
