import React, {useEffect} from "react";
import { Route } from "react-router-dom";
import "./styles/App.scss";
import { gsap } from "gsap";
import Header from "./components/header";
import Navigation from "./components/navigation";
import Home from "./pages/home"
import Services from "./pages/services"
import News from "./pages/news"
import About from "./pages/about"



const routes = [
  { path: "/", name: "Home", Component: Home },
  { path: "/services", name: "services", Component: Services },
  { path: "/news", name: "news", Component: News },
  { path: "/about", name: "about", Component: About }
  // { path: "/services", name: "services", Component: Services },
];

function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}


function App() {

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  useEffect(() => {

    let tl = gsap.timeline();

    // tl.from(".line span", 1.8, {
    //   y: 100,
    //   ease: "power4.out",
    //   delay: 1,
    //   skewY: 7,
    //   stagger: {
    //     amount: 0.3
    //   }
    // })
    // .to(".overlay-top", 1.6, {
    //   height: 0,
    //   ease: "expo.inOut",
    //   stagger: 0.4
    // })
    // .to(".intro-overlay", 0, {
    //   css: { display: "none" }
    // })

    // prevents flashing
    tl.to("body", 0, { css: { visibility: "visible" } });
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }, 1000);

    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  return (
    <>
      <Header dimensions={dimensions} />
      <div className='App'>
        {routes.map(({ path, Component }) => (
          <Route key={path} exact path={path}>
            <Component dimensions={dimensions} />
          </Route>
        ))}
      </div>
      <Navigation/>
    </>
  );
}

export default App;
