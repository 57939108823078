import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { ReactComponent as UpArrow } from "../assets/up-arrow-circle.svg";
import logo from "../assets/oi-logo.png";
import { openMenu, closeMenu } from "../animations/menuAnimations";
import gsap from "gsap"

let tl = gsap.timeline();

const Header = ({ history, dimensions }) => {

    const [menuState, setMenuState] = useState({ menuOpened: false });
    useEffect(() => {
        //Listening for page changes.
        history.listen(() => {
            setMenuState({ menuOpened: false });
        });
        if (menuState.menuOpened === true) {
            console.log("OPEN MENU")
            openMenu(dimensions.width);
            // gsap.to('nav', {css :{display: "block"}})
            // gsap.to('body', {css :{overflow: "hidden"}})

            // console.log("OPEN MENU")
            // tl.to('.App',{
            //     duration: 1,
            //     y: '50vh',
            //     ease: 'expo.inOut'
            // })
        } else if (menuState.menuOpened === false) {
            closeMenu();
            console.log("CLOSE MENU")

        }
    },);
    return (
        <div className='header'>
            <div className='container'>
                <div className='row v-center space-between'>
                    <div className='logo'>
                        <NavLink to='/' exact>
                            <img src={logo} alt="Logo" />
                        </NavLink>
                    </div>
                    <div className='nav-toggle'>
                        <div
                        onClick={() => setMenuState({ menuOpened: true })}
                        className='hamburger-menu'>
                        <span></span>
                        <span></span>
                        <span></span>
                        </div>
                        <div
                        className='hamburger-menu-close'
                        onClick={() => setMenuState({ menuOpened: false })}>
                        <UpArrow />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Header);