import React from "react";

const News = () => {
  return (
    <div className='page'>
      <div className='container'>
        <div className='row'>
          <h3>This is the news page</h3>
        </div>
      </div>
    </div>
  );
};

export default News;