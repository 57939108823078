import React, { useEffect, useState } from "react";
import IntroOverlay from "../components/introOverlay";
import { homeAnimation } from "../animations/homeAnimation";

const About = () => {

  const [animationComplete, setAnimationComplete] = useState(false);
  
  const completeAnimation = () => {
     setAnimationComplete(true);
     console.log("ANIMATION COMPLETE", animationComplete)
  };

  useEffect(() => {
    console.log("HOME ANIMATION", animationComplete)
    homeAnimation(completeAnimation);
    setTimeout(()=>{
      completeAnimation()
      console.log("ANIMATION COMPLETED")
    }, 3000)
  }, []);

  return (
    <div>
      {animationComplete === false ? <IntroOverlay /> : ""}
      <div className='page'>
        <div className='container'>
          <div className='row'>
              <h3>Chi siamo</h3>
              <div className="service">
                <h3>About</h3>
                <span></span>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;